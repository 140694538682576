import React from "react"
import tw from 'twin.macro'

const Section = (props) => {

	const variant = props.variant || "1"

	const baseStyle = {
		
		...tw`flex-1 flex flex-col items-center text-lightestGray md:text-lg`,
		".section-wrapper" : {
			...tw`w-full max-w-layoutMax flex-1 py-8 md:(py-12) px-4 flex flex-col`
		},
		"p:not(:last-of-type)" : {
			...tw`mb-4`
		},
		"b,h1,h2" : {
			...tw`text-gray-500`
		},
		h1 : {
			...tw`text-xl mb-3 md:text-2xl`
		},
		h2 : {
			...tw`text-lg mt-3 mb-6 md:text-xl`
		},
		h3 : {
			...tw`mt-3 mb-4`
		}
	}

	const variantStyles = {
		1 : {
			...baseStyle,
			...tw`bg-white`
		},
 		2 : {
			...baseStyle,
			...tw`bg-offWhite2`
		}
	}
	return <section {...props} css={[variantStyles[variant],props.css]}>
		<div id={props.id + "-section-wrapper"} className="section-wrapper">
		{props.children}
		</div>
	</section>
}

export default Section