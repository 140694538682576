import React, { useState, useMemo } from 'react'
import Layout from '../../../components/layout'
import Section from '../../../components/section'
import SEO from '../../../components/seo'
import tw, {css} from 'twin.macro'
import { useMe } from '../../../hooks/useMe'
import { PLANS_BY_NAME } from '../../../constants'
import AuthGate from '../../../components/authGate'
import { navigate } from 'gatsby'
import Icon from '../../../components/icon'
import {
	faHome,
	faCreditCard,
	faKey,
	faTrashCan,
	faLock,
	faChevronUp,
	faChevronDown,
	faGlobe
} from '@fortawesome/free-solid-svg-icons'

const Account = ({children, tab, ...props}) => {

	const { me, meChecked, refreshMe } = useMe()

	const selectedTabCSS = {
		...tw`text-blue border-b-2 font-medium border-blue`
	}

	const SecondaryNavTile = ({id, icon, label, selected, onClick, onToggle, expanded, ...props}) => {

		const [hover, setHover] = useState(false)
		
		const expandable = useMemo(() => {
			return typeof expanded !== 'undefined'
		}, [expanded])

		return <>
			<div
				tw="flex items-center text-black md:text-gray-300 text-sm py-2 md:py-4 cursor-pointer md:border-l-4 md:border-black" 
				css={[
					(selected || hover) && {
						...tw`bg-gray-100 md:bg-transparent md:border-viking md:text-white`
					},
					expandable && {
						...tw`text-base`
					}
				]}
				onClick={() => {
					if (expandable) {
						onToggle()
					}
					else {
						onClick(id)
					}
				}}
				onMouseEnter={() => setHover(true)}
				onMouseLeave={() => setHover(false)}
				{...props}
			>
				<div tw="flex-1 flex items-center">
					<Icon tw="h-4 mx-4" icon={icon}/>
					<div tw="mr-4">{label}</div>
				</div>
				{expandable && <Icon tw="md:hidden h-4 mx-4" icon={expanded ? faChevronUp : faChevronDown}/>}
			</div>
			<div tw="hidden md:block border-b border-gray-700"></div>
		</>
	}

	const SecondaryNav = ({tiles, currentTabId, ...props}) => {
		const selectedTile = tiles.find(t => t.id === currentTabId)
		const [expanded, setExpanded] = useState(false)
		return <>
			<div tw="w-full md:hidden fixed md:static bg-blue h-12"></div>
			{ expanded && <div 
					tw="w-full h-full md:hidden fixed md:static bg-black bg-opacity-20 mt-12" 
					onClick={evt => {
						setExpanded((prev) => !prev)
					}}
				>
				</div> 
			}
			<div tw="flex flex-col w-full md:w-72 md:block fixed md:static bg-transparent md:bg-black" {...props}>
				<div tw="mx-4 md:mx-0 bg-white md:bg-transparent rounded-md md:rounded-none">
					<SecondaryNavTile 
						tw="md:hidden"
						key={currentTabId + "-selected"}
						{...selectedTile}
						onToggle={evt => {
							setExpanded((prev) => !prev)
						}}
						expanded={expanded}
						onClick={evt => {}}
					/>
					<nav tw="hidden md:block" css={[
						expanded && { ...tw`block` }
					]}>
						{tiles.map((tile) => <SecondaryNavTile
							key={tile.id}
							onClick={(id) => {
								navigate(tile.path)
							}}
							selected={tile.id === currentTabId}
							{...tile}
						/>)}
					</nav>
				</div>
		</div>
		</>
	}

	const navTiles = useMemo(() => [
		{id:"profile",label:"Profile", title:"Your file.io profile", icon:faHome},
		{id:"password",label:"Password", title:"Change your password", icon:faLock},
		{id:"plan",label:"Plan Subscription", title:"Plan subscription details", icon:faCreditCard},
		{id:"apikeys",label:"API Keys", title:"Manage your API keys", icon:faKey},
		{id:"customdomain",label:"Custom Domain", title:"Configure a custom domain name", icon:faGlobe},
		{id:"accountdeletion",label:"Account Deletion", title:"Delete your account", icon:faTrashCan}
	].map(tile => { tile.path = `/account/${tile.id}`; return tile }))

	const title = useMemo(() => {
		const tile = navTiles.find(nt => nt.id === tab)
		return tile && tile.title
	}, [tab])

	return <AuthGate {...props}>
		<Layout>
			<SEO title={title} />
			<div tw="flex h-full w-full flex-1">
				<SecondaryNav 
					tw=""
					currentTabId={tab}
					tiles={navTiles}>
				</SecondaryNav>
				<div tw="flex-1 h-full mt-12 md:mt-0 p-4 md:p-8">
					<h1>{title}</h1>
					{ children }
				</div>
			</div>
		</Layout>
	</AuthGate>
}

export default Account